import styled from "styled-components"

export const ButtonStyles = styled.button`
  color: rgb(51, 44, 40);
  background-color: var(--primary);
  font-family: 'Inter', sans-serif;
  border: 0;
  text-decoration: none;
  padding: 17px 51px;
  transition: color 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  position: relative;
  align-self: flex-start;
  display: inline-flex;
  align-items: center;
  opacity: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    right: 0;
    bottom: -6px;
    background-color: #000;
    transition: width 0.3s ease;
    width: 0;
  }

  &:focus {
    color: #000;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: #000;
      opacity: .8;

      &::after {
        width: 100%;
      }
    }
  }
`
